<script setup>
// Props declaration with the Composition API
const props = defineProps({
    field: {
        type: Object,
        default: () => ({}),
    },
});

// Computed properties with the Composition API
const canInclude = computed(() => props.field.visibility !== 'disabled');

const canShow = computed(() => props.field.visibility !== 'hidden' && props.field.displayName !== 'Hidden');

const attrs = computed(() => ({
    class: props.field.cssClasses,
    ...formieAttributesTable(props.field.containerAttributes),
}));
</script>

<template>
    <component
        v-bind="attrs"
        :is="'FormieInput'+field.displayName"
        v-if="canInclude"
        v-show="canShow"
        :field="field"
        class="relative"
    />
</template>
